import React from 'react'
import styled from 'styled-components'
import { navigate } from 'gatsby-link'
import MediaQuery from 'react-responsive'
import Cookies from 'universal-cookie'
import { getPositionFromId } from '../../utils/api'
import { signOut } from '../../utils/auth-utils'

/* eslint-disable jsx-a11y/anchor-is-valid */
export default class Club extends React.Component {
  static defaultProps = {
    hideBG: true,
    underlineColor: '#C14C40',
  }

  constructor(props) {
    super(props)
    this.state = {
      style: null,
      isPermission: '',
    }
  }

  async componentDidMount() {
    const w = document.documentElement.clientWidth
    const bgColor = this.props.hideBG ? null : { backgroundColor: '#E8E8E8' }
    if (w <= 450) {
      this.setState({ style: { padding: '20px 10px', ...bgColor } })
    } else {
      this.setState({ style: { padding: '20px 0', ...bgColor } })
    }
    let uid = this.props.cookieAuth
      console.log(uid)
      const positionData = await getPositionFromId(uid)
      if (positionData && positionData.data) {
        this.setState({ isPermission: positionData.data.position || '' })
      }
      console.log(this.state.isPermission)    
  }

  logout = () => {
    const cookies = new Cookies();
    signOut()
    cookies.remove('_id',{ path: '/' })
  }
  styleFont1 = { fontFamily: `'Noto Serif', serif` }
  handleLink = () => {
    navigate(`my-club/register`)
  }
  display(props) {
    const pathname = props.location.pathname
    return (
      <div className="row no-gutters club" style={this.state.style}>
        <div style={{ width: '100%' }}>
          <div className="club__header">
            <hr className="club__line" />
            <h3 style={this.styleFont1}>KMITL Engineer Alumni</h3>
          </div>
          <div className="club__sub-header">
            <p>Website : http://kmitl.com/th/about-us/</p>
            <p className="club__sub-header-out"> <a href="https://www.kmitl.com/th/Home/" onClick={this.logout}>{props.locale['SIGN_OUT']} </a></p>
          </div>
          <MediaQuery maxWidth={768}>
            <div
              className="wrapper-btn-personal btn-edit-personal-info"
              onClick={() => navigate(`my-club/register`)}
            >
              {props.locale['EDIT_PERSONAL_INFO']}
            </div>
          </MediaQuery>
          <div className="flex align-center space-between">
            <div className="flex">
            <MediaQuery minWidth={769}>
            <div
                className={`club__tab__item ${
                  pathname === '/my-club' || pathname === '/my-club/'
                    ? 'club__tab__item--active'
                    : ''
                }`}
                onClick={() => navigate(`/my-club`)}
              >
                {props.locale['MEMBER']}
              </div>
            </MediaQuery>
            
            <MediaQuery maxWidth={768}>
            <div
                className={`club__tab__item_mobile ${
                  pathname === '/my-club' || pathname === '/my-club/'
                    ? 'club__tab__item--active'
                    : ''
                }`}
                onClick={() => navigate(`/my-club`)}
              >
                {props.locale['MEMBER']}
              </div>
            </MediaQuery>
            <MediaQuery minWidth={769}>
              <div
                className={`club__tab__item ${
                  pathname === '/my-club/activity' ||
                  pathname === '/my-club/activity/'
                    ? 'club__tab__item--active'
                    : ''
                }`}
                onClick={() => navigate(`/my-club/activity`)}
              >
                {props.locale['ACTIVITIES']}
              </div>
            </MediaQuery>
            <MediaQuery maxWidth={768}>
              <div
                className={`club__tab__item_mobile ${
                  pathname === '/my-club/activity' ||
                  pathname === '/my-club/activity/'
                    ? 'club__tab__item--active'
                    : ''
                }`}
                onClick={() => navigate(`/my-club/activity`)}
              >
                {props.locale['ACTIVITIES']}
              </div>
            </MediaQuery>
            {(((this.state.isPermission == 'Admin') || (this.state.isPermission == 'Owner')) && (
              <MediaQuery minWidth={769}>
              <div
                className={`club__tab__item ${
                  pathname === '/my-club/admin' || pathname === '/my-club/admin/'
                    ? 'club__tab__item--active'
                    : ''
                }`}
                onClick={() => navigate(`/my-club/admin`)}
              >
                {props.locale['ADMINISTRATOR']}
              </div>
              </MediaQuery>
                )) ||
                 null}
            {(((this.state.isPermission == 'Admin') || (this.state.isPermission == 'Owner')) && (
              <MediaQuery maxWidth={768}>
              <div
                className={`club__tab__item_mobile ${
                  pathname === '/my-club/admin' || pathname === '/my-club/admin/'
                    ? 'club__tab__item--active'
                    : ''
                }`}
                onClick={() => navigate(`/my-club/admin`)}
              >
                {props.locale['ADMINISTRATOR']}
              </div>
              </MediaQuery>
                )) ||
                 null}
            </div>
            <MediaQuery minWidth={768}>
              <div
                className="btn-edit-personal-info"
                onClick={() => navigate(`my-club/register`)}
              >
                {props.locale['EDIT_PERSONAL_INFO']}
              </div>
            </MediaQuery>
          </div>
          <div className="club__card" style={{ minHeight: 244 }}>
            {props.children}
          </div>
        </div>
      </div>
    )
  }

  render() {
    return this.display(this.props)
  }
}
