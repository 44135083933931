import React from 'react'
import { BoxDetail } from '../box'
import Club from '../club/club-activities'

export default props => (
  <div>
    <BoxDetail>
      <Club {...props} />
    </BoxDetail>
  </div>
)
