import React, { Component, Fragment } from 'react'
import styled from 'styled-components'
import media from 'styled-media-query'
import Button from '../form/buttons'
import IconShare from '../../images/ic_share.svg'
import ClubLayout from './layout'
import { navigate } from 'gatsby-link'
import moment from 'moment'
import { getLanguage } from '../../locale'
import { BoxDetailClub, BoxWrapperClub } from '../box'
import Carousel from '../carousel'
import { ImageBanner } from '../banner'
import CardPanel from '../card/panel'
import CardCarousel from '../card/card-carousel'
import { getListEvents } from '../../utils/api'

const ColumnHeader = styled.div`
  opacity: 0.87;
  font-family: RSU-Bold;
  font-size: 24px;
  color: #3f3131;
`

const Line = styled.hr`
  border: 0;
  height: 1px;
  background: #f0f0f1;
  margin: 20px 0;
`
const EventContainer = styled.div`
  padding: 24px 0 12px 0;
  ${media.lessThan('medium')`
    padding-top: 0;
  `}
`

const DateFrame = styled.div`
  background: #892a24;
  border-radius: 4px;
  min-width: 100px;
  width: 100px;
  color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-right: 48px;
  height: 140px;

  span {
    opacity: 0.87;
    font-size: 48px;
    color: #ffffff;
    letter-spacing: -0.5px;
    text-align: left;
  }

  span:nth-child(2) {
    margin-top: 12px;
    font-size: 20px;
    letter-spacing: 0.25px;
  }
`

const EventHeader = styled.h3`
  opacity: 0.87;
  font-size: 34px;
  color: #463838;
  letter-spacing: 0.25px;
  margin-bottom: 0.4em;
  ${media.lessThan('medium')`
    font-size: 20px;
  `}
`

const EvenrSubHeader = styled.h4`
  opacity: 0.6;
  font-size: 20px;
  color: #3f3131;
  letter-spacing: 0.25px;
  ${media.lessThan('medium')`
    font-size: 14px;
  `}
`

const EventDatetime = styled.div`
  opacity: 0.87;
  font-size: 16px;
  color: #3f3131;
  letter-spacing: 0.5px;
  line-height: 28px;
  span {
    margin-right: 24px;
  }
  ${media.lessThan('medium')`
    font-size: 14px;
  `}
`

const SubDatetimeContainer = styled.div`
  display: flex;
  ${media.lessThan('medium')`
    display: block;
  `}
`

const SubDatetime = styled.div`
  display: flex;
`

const SubDatetimeMiddle = styled.div`
  display: flex;
  & div > i {
    margin: 0 12px 0 24px;
    ${media.lessThan('medium')`
      margin: 0 12px 0 0;
    `}
  }
`

const ButtonContainer = styled.div`
  margin-left: 136px;
  ${media.lessThan('medium')`
    margin-left: 0;
    margin-top: 24px;
    text-align: center;

    & button:nth-child(1) {
      margin-bottom: 12px !important;
    }
  `}
`
const reArrayListEvent = async (listevent, includePublicView) => {
  const originalListEventArray = listevent
  console.log(originalListEventArray)
  let newListClubArray = []
  let subArray = []
  if (originalListEventArray.length > 0) {
    let newIndex = 0
    let counter = 0
    let limitCard = 4
    let index = 0
    originalListEventArray.forEach((arrayData) => {
      for (index = 0; index < arrayData.length; index++) {
        subArray[counter] = arrayData[index]
        counter = counter + 1
        if (counter == limitCard) {
          console.log('limit')
          newListClubArray[newIndex] = subArray
          newIndex = newIndex + 1
          counter = 0
          subArray = []
        }
        if ((originalListEventArray.length == (newIndex + 1)) && ((index + 1) == arrayData.length)) {
          newListClubArray[newIndex] = subArray
        }
      }
    })
    if (includePublicView === true) {
      // temp public can remove after complete re-catagory club event
      const tempPublic = await getListEvents('public')
      if (newListClubArray[newIndex].length == 4) {
        newListClubArray[newIndex + 1] = [tempPublic.data.events[0][0]]
      } else {
        newListClubArray[newIndex].push(tempPublic.data.events[0][0])
      }
    }
  } else {
    if (includePublicView === true) {
      const tempPublic = await getListEvents('public')
      newListClubArray = [[tempPublic.data.events[0][0]]]
    }
  }
  console.log(newListClubArray)
  return newListClubArray
}

const reArrayCardMobile = async (dataList, includePublicView) => {
  let newList = []
  if (dataList.length > 0) {
    dataList.forEach(v => {
      newList.push(...v)
    })
    //console.log('First',newList)
  }
  if (includePublicView === true) {
    const tempPublic = await getListEvents('public')
    if (newList.length > 0) {
      newList.push(tempPublic.data.events[0][0])
    } else {
      newList = [tempPublic.data.events[0][0]]
    }
  }
  //console.log('Final',newList)
  return newList
}

export default class ClubActivities extends Component {
  constructor(props) {
    super(props)
    this.state = {
      tempUpcoming: [],
      temppastEvent: [],
      templistClubEvent: [],
      tempupComingEventsMobile: [],
      temppastEventsMobile: [],
      templistClubEventsMobile: [],
      eventReady: false
    }
  }

  async componentDidMount() {
  }

  async componentDidUpdate(prevProps) {
    if ((!(this.props.state.inClubUpcoming === 'undefined' && this.props.state.inClubHistory === 'undefined' && this.props.state.listClub === 'undefined')) && (!this.state.eventReady)) {
      this.setState({ eventReady: true})
      const tempUpcoming = await reArrayListEvent(this.props.state.inClubUpcoming, false)
      const temppastEvent = await reArrayListEvent(this.props.state.inClubHistory, false)
      const templistClubEvent = await reArrayListEvent(this.props.state.listClub, false)
      const tempupComingEventsMobile = await reArrayCardMobile(this.props.state.inClubUpcoming, false)
      const temppastEventsMobile = await reArrayCardMobile(this.props.state.inClubHistory, false)
      const templistClubEventsMobile = await reArrayCardMobile(this.props.state.listClub, false)
      this.setState({ tempUpcoming: tempUpcoming })
      this.setState({ temppastEvent: temppastEvent })
      this.setState({ templistClubEvent: templistClubEvent })
      this.setState({ tempupComingEventsMobile: tempupComingEventsMobile })
      this.setState({ temppastEventsMobile: temppastEventsMobile })
      this.setState({ templistClubEventsMobile: templistClubEventsMobile })
    }
  }

  render() {
    const clubStyle = true
    const style1 = { height: 10 }
    const lang = getLanguage()
    return (
      <ClubLayout {...this.props}>
        <div style={{ marginBottom: 48 }}>
          <BoxWrapperClub>
            <div style={style1} />
            {this.state.templistClubEvent.length > 0 && (
              <BoxDetailClub>
                <CardPanel
                  title={this.props.locale.OUR_ACTIVITY}
                  // onSeeAll={props.onGoUpcoming}
                  locale={this.props.locale}
                >
                  <CardCarousel
                    className="is-card-slider"
                    data={this.state.templistClubEvent}
                    dataMobile={this.state.templistClubEventsMobile}
                    onShare={id => this.props.onShare(id)}
                    onGoDetail={id => this.props.onGoDetail(id)}
                    locale={this.props.locale}
                    clubStyle={clubStyle}
                  />
                </CardPanel>
              </BoxDetailClub>
            )}

            <div style={style1} />
            {this.state.tempUpcoming.length > 0 && (
              <BoxDetailClub>
                <CardPanel
                  title={this.props.locale.UPCOMING_EVENTS}
                  // onSeeAll={props.onGoUpcoming}
                  locale={this.props.locale}
                >
                  <CardCarousel
                    className="is-card-slider"
                    data={this.state.tempUpcoming}
                    dataMobile={this.state.tempupComingEventsMobile}
                    onShare={id => this.props.onShare(id)}
                    onGoDetail={id => this.props.onGoDetail(id)}
                    locale={this.props.locale}
                    clubStyle={clubStyle}
                  />
                </CardPanel>
              </BoxDetailClub>
            )}

            <div style={style1} />
            {this.state.temppastEvent.length > 0 && (
              <BoxDetailClub>
                <CardPanel
                  title={this.props.locale.PAST_EVENTS}
                  // onSeeAll={props.onGoUpcoming}
                  locale={this.props.locale}
                >
                  <CardCarousel
                    className="is-card-slider"
                    data={this.state.temppastEvent}
                    dataMobile={this.state.temppastEventsMobile}
                    onShare={id => this.props.onShare(id)}
                    onGoDetail={id => this.props.onGoDetail(id)}
                    locale={this.props.locale}
                    clubStyle={clubStyle}
                  />
                </CardPanel>
              </BoxDetailClub>
            )}

          </BoxWrapperClub>
        </div>
      </ClubLayout>
    )
  }
}
